"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionStageType = exports.SessionType = void 0;
var SessionType;
(function (SessionType) {
    SessionType[SessionType["Draft"] = 0] = "Draft";
    SessionType[SessionType["Bet"] = 1] = "Bet";
    SessionType[SessionType["Game"] = 2] = "Game";
    SessionType[SessionType["Quiz"] = 3] = "Quiz";
})(SessionType || (exports.SessionType = SessionType = {}));
var SessionStageType;
(function (SessionStageType) {
    SessionStageType[SessionStageType["Draft"] = 0] = "Draft";
    SessionStageType[SessionStageType["Lobby"] = 1] = "Lobby";
    SessionStageType[SessionStageType["Active"] = 2] = "Active";
    SessionStageType[SessionStageType["Close"] = 3] = "Close";
})(SessionStageType || (exports.SessionStageType = SessionStageType = {}));
