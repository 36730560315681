"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionForm = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const context_1 = require("src/app/context");
const form_1 = require("src/components/atoms/form");
const FormFields_1 = require("src/components/atoms/form/FormFields");
const request_utils_1 = require("src/support/request.utils");
const sessionFormFields = [
    { id: "title", label: "title", required: true },
    {
        id: "type",
        required: true,
        label: "type",
        inputProps: {
            type: "select",
            options: [
                { value: 1, text: "Single Question" },
                { value: 3, text: "Quiz" },
            ],
        },
    },
    { id: "details", label: "details" },
    { id: "tag", label: "link tag" },
    // { id: "maxUsers", label: "max users" },
    {
        id: "options",
        label: "options",
        required: true,
        inputProps: { type: "list", min: 2 },
    },
];
const SessionForm = ({ onEnd = null, onProgress = null, data = null, children, }) => {
    const { state } = (0, context_1.useAppContext)();
    const { targetSession } = state;
    const isEditForm = (0, react_1.useMemo)(() => targetSession?.id != null, [targetSession]);
    const handleSubmit = (0, react_1.useCallback)((e, formFields) => {
        e.preventDefault();
        if (onProgress) {
            onProgress();
        }
        if (isEditForm) {
            (0, request_utils_1.PUT_JSON)(`/api/games/${targetSession.id}`, { body: formFields })
                .then((res) => res.json())
                .then((res) => {
                if (onEnd) {
                    onEnd(res);
                }
            });
        }
        else {
            (0, request_utils_1.POST_JSON)("/api/games", { body: formFields })
                .then((res) => res.json())
                .then((res) => {
                if (onEnd) {
                    onEnd(res);
                }
            });
        }
    }, [isEditForm]);
    const defaultValues = (0, react_1.useMemo)(() => {
        return sessionFormFields.map((it) => {
            const field = { inputProps: {}, ...it };
            if (!targetSession || !targetSession[field.id]) {
                return field;
            }
            field.inputProps.defaultValue = targetSession[field.id];
            return field;
        });
    }, []);
    return (react_1.default.createElement(form_1.Form, { onSubmit: handleSubmit, className: "flex flex-col gap-4" },
        react_1.default.createElement(FormFields_1.FormFields, { fields: defaultValues }),
        children));
};
exports.SessionForm = SessionForm;
