"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signOut = exports.signIn = void 0;
const request_utils_1 = require("src/support/request.utils");
async function signIn() {
    return (0, request_utils_1.GET)("/api/auth", {
        headers: {
            "Cache-Control": "no-cache",
        },
        credentials: "include",
    }).then(async (res) => {
        if (res.status === 301) {
            return { user: null, authUrl: await res.text() };
        }
        return res.json();
    });
}
exports.signIn = signIn;
async function signOut() {
    return (0, request_utils_1.GET)("/api/auth", {
        method: "DELETE",
        credentials: "include",
    });
}
exports.signOut = signOut;
