"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionFormDrawer = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const context_1 = require("src/app/context");
const drawer_1 = require("src/components/atoms/drawer");
const _1 = require(".");
const button_1 = require("src/components/atoms/button");
const SessionFormDrawer = ({ data = null, onData = null } = {}) => {
    const { state, dispatch } = (0, context_1.useAppContext)();
    const handleClose = (0, react_1.useCallback)(() => {
        dispatch((state) => {
            return { ...state, sessionFormVisible: false, targetSession: null };
        });
    }, [dispatch]);
    const handleEnd = (payload) => {
        if (onData) {
            onData(payload);
        }
        handleClose();
    };
    const handleProgress = () => { };
    const handleDiscard = () => {
        handleClose();
    };
    return (react_1.default.createElement(drawer_1.Drawer, { open: state?.sessionFormVisible, destroyOnClose: true, onClose: handleClose, minWidth: "60vw" },
        react_1.default.createElement("div", { className: "px-6 relative h-full overflow-y-auto" },
            react_1.default.createElement(_1.SessionForm, { data: data, onProgress: handleProgress, onEnd: handleEnd },
                react_1.default.createElement("div", { className: "sticky bottom-0 py-2 flex gap-4 items-center bg-white border-t border-black" },
                    react_1.default.createElement(button_1.Button, { primary: true, className: "grow" }, "Submit"),
                    react_1.default.createElement(button_1.Button, { type: "reset", onClick: handleDiscard, className: "grow" }, "Discard"))))));
};
exports.SessionFormDrawer = SessionFormDrawer;
