"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const label_1 = require("./label");
const button_1 = require("src/components/atoms/button");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const input_1 = require("src/components/atoms/input");
const HiddenInput_1 = require("./HiddenInput");
function ListInput({ defaultValue, min, }) {
    const defaultOptionsValue = (0, react_1.useMemo)(() => {
        if (defaultValue) {
            return JSON.stringify(defaultValue);
        }
        return "";
    }, [defaultValue]);
    const optionRef = (0, react_1.useRef)(defaultOptionsValue);
    const [parts, setPart] = (0, react_1.useState)(parseOptionsValue(defaultOptionsValue, min));
    const handleAddOption = (0, react_1.useCallback)((e) => {
        e.preventDefault();
        setPart((prev) => prev.concat([{ text: "" }]));
    }, []);
    const handleRemove = (targetIndex) => {
        setPart((prev) => {
            const next = prev.filter((_, index) => index !== targetIndex);
            return validateOptionslength(next, min);
        });
    };
    const handleInput = (event, index) => {
        setPart((prev) => {
            const _next = prev.slice();
            _next[index] = { text: event.target?.value || "" };
            const _value = _next.filter((it) => !!it.text.trim());
            optionRef.current = JSON.stringify(_value);
            return _next;
        });
    };
    return (react_1.default.createElement("div", { className: "relative flex flex-col gap-4 p-2 border border-black text-md" },
        parts.map((it, index) => {
            const isRequired = index < min;
            return (react_1.default.createElement("div", { key: index, className: "relative flex flex-col" },
                react_1.default.createElement("div", { className: "flex items-center gap-2 justify-between" },
                    react_1.default.createElement(label_1.Label, { id: index, required: isRequired },
                        "Option ",
                        index + 1),
                    react_1.default.createElement("div", { className: "flex items-center gap-1 text-[0.8em] cursor-pointer select-none hover:text-red-500", onClick: () => handleRemove(index) },
                        react_1.default.createElement("b", null, "remove"),
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faTimes }))),
                react_1.default.createElement(input_1.Input, { value: it.text, onChange: (e) => handleInput(e, index), required: isRequired })));
        }),
        react_1.default.createElement(button_1.Button, { onClick: handleAddOption }, "add new option"),
        react_1.default.createElement(HiddenInput_1.HiddenInput, { name: "options", value: optionRef.current })));
}
exports.default = ListInput;
function parseOptionsValue(value, minLength) {
    let arr = [];
    try {
        if (!value) {
            arr = [];
        }
        else if (Array.isArray(value)) {
            arr = value;
        }
        else {
            arr = JSON.parse(value) || [];
        }
        if (typeof arr === "string") {
            arr = [{ text: arr }];
        }
    }
    catch (error) {
        arr = [];
    }
    return validateOptionslength(arr, minLength);
}
function validateOptionslength(arr, minLength) {
    const length = arr?.length || 0;
    if (length < minLength) {
        arr = arr.concat(new Array(minLength - length).fill({ text: "" }));
    }
    return arr;
}
