"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePage = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const button_1 = require("src/components/atoms/button");
const session_list_1 = require("src/components/organizms/session-list");
const useSessions_1 = require("src/hooks/useSessions");
const searchInput_1 = require("src/components/atoms/input/searchInput");
const auth_1 = require("src/components/molecules/auth");
const context_1 = require("src/app/context");
const useSessionForm_1 = require("src/hooks/useSessionForm");
const session_form_drawer_1 = require("src/components/organizms/session-form/session-form-drawer");
const HomePage = () => {
    const { loading, data, error, load, set } = (0, useSessions_1.useSessions)({ preload: true });
    const { state, dispatch } = (0, context_1.useAppContext)();
    const { show } = (0, useSessionForm_1.useSessionForm)();
    const isLogged = (0, react_1.useMemo)(() => {
        return state?.logged;
    }, [state?.logged]);
    const handleSearch = (0, react_1.useCallback)(() => {
        load();
    }, [load]);
    const handleData = (data) => {
        load();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("article", { className: "flex flex-col gap-8 items-center text-center mx-auto px-6 sm:w-fit" },
            react_1.default.createElement("h1", { className: "mt-8 text-5xl sm:text-6xl" }, "The Quizdation"),
            react_1.default.createElement("section", { className: "w-full" }, isLogged ? (react_1.default.createElement(button_1.Button, { primary: true, className: "w-full capitalize", onClick: () => show() }, "Create new session")) : (state?.ready && (react_1.default.createElement(auth_1.SignIn, null,
                react_1.default.createElement(button_1.Button, { primary: true, className: "w-full" }, "Sign In"))))),
            react_1.default.createElement("section", { className: "flex flex-col gap-4 w-full" },
                react_1.default.createElement(searchInput_1.SearchInput, { className: "w-full", onSubmit: handleSearch }, "Search"),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement("h3", { className: "text-left text-xl" }, "Search Results"),
                    react_1.default.createElement(session_list_1.SessionList, { loading: loading, items: data || [] })))),
        react_1.default.createElement(session_form_drawer_1.SessionFormDrawer, { onData: handleData })));
};
exports.HomePage = HomePage;
