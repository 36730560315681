"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGame = void 0;
const react_1 = require("react");
const request_utils_1 = require("src/support/request.utils");
const initialState = {
    loading: false,
    data: null,
    error: null,
};
const useGame = ({ tag, preload = false }) => {
    const [payload, dispatch] = (0, react_1.useReducer)(reducer, initialState);
    const load = (0, react_1.useCallback)(async (filter = null) => {
        dispatch({ type: "loading", payload: true });
        await (0, request_utils_1.GET)(`/api/game?tag=${tag}`, null, 500)
            .then((res) => res.json())
            .then((data) => {
            dispatch({ type: "data", payload: data });
        })
            .catch((error) => {
            dispatch({ type: "error", payload: error });
        });
    }, [dispatch]);
    const clear = (0, react_1.useCallback)(() => { }, [dispatch]);
    const patch = (0, react_1.useCallback)(({ user, ...update } = {}) => {
        dispatch({ type: "patch", payload: update });
    }, [dispatch]);
    (0, react_1.useEffect)(() => {
        if (preload) {
            load();
        }
    }, [preload]);
    return {
        ...payload,
        load,
        clear,
        patch,
    };
};
exports.useGame = useGame;
function reducer(state, { type, payload }) {
    switch (type) {
        case "loading": {
            state.loading = payload;
            break;
        }
        case "data": {
            state.loading = false;
            state.data = payload;
            state.error = null;
            break;
        }
        case "patch": {
            state.loading = false;
            state.data = Object.assign({}, state.data, payload);
            state.error = null;
            break;
        }
        case "error": {
            state.loading = false;
            state.error = payload;
            state.data = null;
            break;
        }
    }
    return { ...state };
}
