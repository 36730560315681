"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GamePage = void 0;
const tslib_1 = require("tslib");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const react_1 = tslib_1.__importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const link_1 = require("src/components/atoms/link");
const useGame_1 = require("src/hooks/useGame");
const session_view_1 = require("src/components/organizms/session-view");
const session_model_1 = require("src/models/session.model");
const GamePage = () => {
    const { tag } = (0, react_router_dom_1.useParams)();
    const { loading, data, error, patch } = (0, useGame_1.useGame)({ tag, preload: true });
    const [sourceData] = userBroadcast({ id: data?.id, start: data?.stage !== session_model_1.SessionStageType.Close });
    (0, react_1.useEffect)(() => {
        if (sourceData) {
            patch(sourceData);
            console.log('patch', sourceData);
        }
    }, [sourceData, patch]);
    if (loading) {
        return (react_1.default.createElement("div", { className: "flex justify-center p-6" },
            react_1.default.createElement("h1", { className: "flex items-center gap-2 uppercase text-xl" },
                react_1.default.createElement("span", null, "Loading"),
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSync, className: "animate-spin" }))));
    }
    if (!data) {
        return (react_1.default.createElement("div", { className: "flex flex-col p-6 gap-2 justify-center text-center" },
            react_1.default.createElement("h1", { className: "uppercase text-xl font-thin" }, "Game not found"),
            react_1.default.createElement("p", { className: "flex justify-center" },
                react_1.default.createElement(link_1.NavLink, { to: "/", className: "flex gap-2 items-center capitalize border-b border-black" },
                    react_1.default.createElement("span", null, "go home"),
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faHome })))));
    }
    const { title, details } = data;
    return (react_1.default.createElement("article", { className: "flex flex-col gap-6 p-6" },
        react_1.default.createElement("section", { className: "text-center" },
            react_1.default.createElement("h1", { className: "text-2xl" }, title),
            details ? react_1.default.createElement("h3", { className: "text-md font-thin" }, details) : null),
        react_1.default.createElement(session_view_1.SessionContent, { data: data })));
};
exports.GamePage = GamePage;
function userBroadcast({ id, start = false }) {
    const ref = (0, react_1.useRef)(null);
    const [state, setState] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        if (!id || !start) {
            return;
        }
        if (ref.current) {
            ref.current.close();
        }
        const source = new EventSource(`/api/games/${id}/broadcast`, {
            withCredentials: true,
        });
        source.onmessage = (message) => {
            const messageData = JSON.parse(message.data);
            setState(messageData);
        };
        source.onerror = (error) => {
            console.log("stream error");
        };
        ref.current = source;
        return () => {
            source.close();
        };
    }, [id, start]);
    return [state];
}
// let stream;
// function subscribeToUserBroadcast({ id }) {
//   if (stream) {
//     stream.close();
//   }
//   stream = new EventSource(`/api/games/${id}/broadcast`, {
//     withCredentials: true,
//   });
//   stream.onmessage = (message) => {
//     const messageData = JSON.parse(message.data);
//     console.log(messageData);
//   };
//   stream.onerror = (error) => {
//     console.log("stream error");
//   };
// }
