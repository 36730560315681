// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {

    width: 100%
}

input[type="search"] {

    border-width: 1px;

    border-color: currentColor;

    padding: 0.5rem;

    outline: 2px solid transparent;

    outline-offset: 2px
}`, "",{"version":3,"sources":["webpack://./client/src/components/atoms/input/style.css"],"names":[],"mappings":"AACE;;IAAA;AAAa;;AAIb;;IAAA,iBAA6C;;IAA7C,0BAA6C;;IAA7C,eAA6C;;IAA7C,8BAA6C;;IAA7C;AAA6C","sourcesContent":["input {\n  @apply w-full;\n}\n\ninput[type=\"search\"] {\n  @apply p-2 border border-current outline-none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
