"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
const tslib_1 = require("tslib");
const React = tslib_1.__importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const game_1 = require("src/pages/game");
const account_1 = require("src/pages/account");
const home_1 = require("src/pages/home");
const navigation_1 = require("src/components/molecules/navigation");
const context_1 = require("src/app/context");
const App = () => (React.createElement(React.Fragment, null,
    React.createElement(context_1.AppStore, null,
        React.createElement(react_router_dom_1.BrowserRouter, null,
            React.createElement("div", { className: "min-h-screen grid grid-rows-[auto_1fr]" },
                React.createElement(navigation_1.Navigation, null),
                React.createElement("div", null,
                    React.createElement(react_router_dom_1.Routes, null,
                        React.createElement(react_router_dom_1.Route, { path: "play/:tag", element: React.createElement(game_1.GamePage, null) }),
                        React.createElement(react_router_dom_1.Route, { path: "account", element: React.createElement(account_1.AccountPage, null) }),
                        React.createElement(react_router_dom_1.Route, { path: "*", element: React.createElement(home_1.HomePage, null) }))))))));
exports.App = App;
