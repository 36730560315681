"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navigation = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const link_1 = require("src/components/atoms/link");
const react_router_dom_1 = require("react-router-dom");
require("./style.css");
const context_1 = require("src/app/context");
const auth_1 = require("src/components/molecules/auth");
const Navigation = () => {
    let location = (0, react_router_dom_1.useLocation)();
    const { state, dispatch } = (0, context_1.useAppContext)();
    const isLogged = (0, react_1.useMemo)(() => {
        return state?.logged;
    }, [state?.logged]);
    // const isGame = useMemo(() => {
    //   return /^\/play\//.exec(location.pathname) != null;
    // }, [location.pathname]);
    const isAccount = (0, react_1.useMemo)(() => {
        return /^\/(account)/.exec(location.pathname) != null;
    }, [location.pathname]);
    // const isHome = !isAccount && !isGame;
    const userLink = (0, react_1.useMemo)(() => {
        if (isAccount) {
            return isLogged ? (react_1.default.createElement(auth_1.SignOut, null,
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSignOut }))) : (react_1.default.createElement(auth_1.SignIn, null,
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSignIn })));
        }
        return (react_1.default.createElement(link_1.NavLink, { to: "/account" },
            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faUser })));
    }, [isLogged, isAccount]);
    return (react_1.default.createElement("nav", null,
        react_1.default.createElement(link_1.NavLink, { to: "/" },
            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faHome })),
        userLink));
};
exports.Navigation = Navigation;
