"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountPage = void 0;
const tslib_1 = require("tslib");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const react_1 = tslib_1.__importStar(require("react"));
const context_1 = require("src/app/context");
const button_1 = require("src/components/atoms/button");
const auth_1 = require("src/components/molecules/auth");
const session_list_1 = require("src/components/organizms/session-list");
const session_admin_1 = require("src/components/organizms/session-admin");
const useSessionForm_1 = require("src/hooks/useSessionForm");
const useSessions_1 = require("src/hooks/useSessions");
const session_form_drawer_1 = require("src/components/organizms/session-form/session-form-drawer");
const session_model_1 = require("src/models/session.model");
const AccountPage = () => {
    const { loading, data, error, load } = (0, useSessions_1.useSessions)({
        preload: false,
        self: true,
    });
    const { show } = (0, useSessionForm_1.useSessionForm)();
    const { state, dispatch } = (0, context_1.useAppContext)();
    const handleShowAdmin = (game) => {
        dispatch((state) => {
            return { ...state, targetSession: game, sessionAdminVisible: true };
        });
    };
    const isLogged = (0, react_1.useMemo)(() => {
        return state?.ready && state?.logged;
    }, [state?.ready, state?.logged]);
    (0, react_1.useEffect)(() => {
        if (isLogged) {
            load();
        }
    }, [isLogged]);
    const handleData = (data) => {
        load();
    };
    if (!state?.ready) {
        return null;
    }
    if (!isLogged) {
        return (react_1.default.createElement("div", { className: "h-full flex items-center justify-center" },
            react_1.default.createElement(auth_1.SignIn, null,
                react_1.default.createElement(button_1.Button, { primary: true }, "Sign In"))));
    }
    const { email, fullName, assets } = state.user;
    const handleItemRender = (game) => {
        if (!game.isAdmin) {
            return null;
        }
        const hasStarted = ![
            session_model_1.SessionStageType.Lobby,
            session_model_1.SessionStageType.Draft,
        ].includes(game.stage);
        const isClosed = [session_model_1.SessionStageType.Close].includes(game.stage);
        return (react_1.default.createElement("div", { className: "flex items-center gap-4" },
            hasStarted ? null : (react_1.default.createElement("div", { className: "cursor-pointer", onClick: () => show(game) },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faEdit }))),
            isClosed ? null : (react_1.default.createElement("div", { className: "cursor-pointer", onClick: () => handleShowAdmin(game) },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faTools })))));
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("article", { className: "flex flex-col gap-6 px-6" },
            react_1.default.createElement("section", { className: "text-center" },
                react_1.default.createElement("label", { className: "font-thin text-xs" }, "welcome back"),
                react_1.default.createElement("h1", { className: "font-bold text-2xl" }, fullName),
                react_1.default.createElement("h3", { className: "font-thin text-xl" }, email)),
            react_1.default.createElement("section", { className: "text-center" },
                react_1.default.createElement("h2", { className: "font-bold text-4xl" }, assets || 0),
                react_1.default.createElement("a", { href: "https://user.the-rivals.online/transaction", className: "link font-thin text-md border-b border-current" }, "add balance")),
            react_1.default.createElement("section", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", null,
                    react_1.default.createElement("h3", { className: "text-left font-thin text-md w-full" }, "Sessions"),
                    react_1.default.createElement(button_1.Button, { primary: true, className: "w-full capitalize", onClick: show }, "Create new session")),
                react_1.default.createElement(session_list_1.SessionList, { loading: loading, items: data || [], onItemRender: handleItemRender }))),
        react_1.default.createElement(session_admin_1.SessionAdminDrawer, { onData: handleData }),
        react_1.default.createElement(session_form_drawer_1.SessionFormDrawer, { onData: handleData })));
};
exports.AccountPage = AccountPage;
