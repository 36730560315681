// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button,
.button {
  border-width: 1px;
  border-color: currentColor;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

  button.primary, .button.primary {
  border-color: currentColor;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (hover: hover) {
  button:not([disabled]):hover,
  .button:not([disabled]):hover {
    box-shadow: 0 4px 4px 0 black;
    transform: translateY(-1px);
  }
}
`, "",{"version":3,"sources":["webpack://./client/src/components/atoms/button/style.css"],"names":[],"mappings":"AAEE;;EAAA,iBAAuC;EAAvC,0BAAuC;EAAvC,mBAAuC;EAAvC,sBAAuC;EAAvC,kBAAuC;EAAvC;AAAuC;;EAGrC;EAAA,0BAAyC;EAAzC,kBAAyC;EAAzC,mDAAyC;EAAzC,oBAAyC;EAAzC;AAAyC;;AAI7C;EACE;;IAEE,6BAA6B;IAC7B,2BAA2B;EAC7B;AACF","sourcesContent":["button,\n.button {\n  @apply py-2 px-12 border border-current;\n\n  &.primary {\n    @apply bg-black text-white border-current;\n  }\n}\n\n@media (hover: hover) {\n  button:not([disabled]):hover,\n  .button:not([disabled]):hover {\n    box-shadow: 0 4px 4px 0 black;\n    transform: translateY(-1px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
