// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    position: sticky;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem
}`, "",{"version":3,"sources":["webpack://./client/src/components/molecules/navigation/style.css"],"names":[],"mappings":"AACE;IAAA,gBAA+D;IAA/D,QAA+D;IAA/D,aAA+D;IAA/D,mBAA+D;IAA/D,8BAA+D;IAA/D,mBAA+D;IAA/D,sBAA+D;IAA/D,oBAA+D;IAA/D;AAA+D","sourcesContent":["nav {\n  @apply sticky top-0 py-2 px-6 flex items-center justify-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
