"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSessionForm = void 0;
const react_1 = require("react");
const context_1 = require("src/app/context");
const initialState = {
    loading: false,
    visible: false,
    data: null,
    error: null,
};
const useSessionForm = ({} = {}) => {
    const { dispatch } = (0, context_1.useAppContext)();
    const [payload, dispatchAction] = (0, react_1.useReducer)(reducer, initialState);
    const show = (0, react_1.useCallback)((payload = null) => {
        dispatch((state) => {
            return { ...state, sessionFormVisible: true, targetSession: payload };
        });
    }, [dispatch]);
    const hide = (0, react_1.useCallback)(() => {
        dispatch((state) => {
            return { ...state, sessionFormVisible: false };
        });
    }, [dispatch]);
    return { show, hide };
};
exports.useSessionForm = useSessionForm;
function reducer(state, { type, payload }) {
    switch (type) {
        case "visibility": {
            state.visible = payload;
            if (!payload) {
                state.loading = false;
                state.data = null;
                state.error = null;
            }
            break;
        }
        case "loading": {
            state.loading = payload;
            break;
        }
        case "data": {
            state.loading = false;
            state.data = payload;
            state.error = null;
            break;
        }
        case "error": {
            state.loading = false;
            state.error = payload;
            state.data = null;
            break;
        }
    }
    return { ...state };
}
