"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const CheckList_1 = require("src/components/atoms/input/CheckList");
const input_1 = require("src/components/atoms/input");
const label_1 = require("src/components/atoms/input/label");
const button_1 = require("src/components/atoms/button");
const session_model_1 = require("src/models/session.model");
const request_utils_1 = require("src/support/request.utils");
const auth_1 = require("src/components/molecules/auth");
const poll_1 = require("src/components/molecules/poll");
function BetView({ data }) {
    const [session, setSession] = (0, react_1.useState)(data);
    (0, react_1.useEffect)(() => {
        setSession(data);
    }, [data]);
    switch (session.stage) {
        case session_model_1.SessionStageType.Draft:
        case session_model_1.SessionStageType.Lobby: {
            return react_1.default.createElement(BetSelectionView, { data: session, dispatch: setSession });
        }
        case session_model_1.SessionStageType.Active: {
            return react_1.default.createElement(BetActiveView, { data: session });
        }
        case session_model_1.SessionStageType.Close: {
            return react_1.default.createElement(BetCloseView, { data: session });
        }
        default: {
            return null;
        }
    }
}
exports.default = BetView;
function BetSelectionView({ data, dispatch }) {
    const { user, options = [], users = 0, state } = data;
    const [error, setError] = (0, react_1.useState)(null);
    const errorText = (0, react_1.useMemo)(() => {
        if (!error) {
            return null;
        }
        return error?.message || error;
    }, [error]);
    const isLogged = (0, react_1.useMemo)(() => {
        return user?.email != null;
    }, [user]);
    const hasBid = (0, react_1.useMemo)(() => {
        return state?.option && state?.value;
    }, [state]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formFields = {};
        for (let [key, value] of formData) {
            formFields[key] = value;
        }
        if (!isLogged) {
            return;
        }
        setError(null);
        (0, request_utils_1.POST_JSON)(`/api/games/${data.id}/user/bet`, { body: formFields })
            .then(async (res) => dispatch(await res.json()))
            .catch((error) => {
            setError(error);
        });
    };
    const handleCancelBid = (e) => {
        if (!isLogged) {
            return;
        }
        setError(null);
        (0, request_utils_1.POST_JSON)(`/api/games/${data.id}/user/cancel`, { body: null })
            .then(async (res) => dispatch(await res.json()))
            .catch((error) => {
            setError(error);
        });
    };
    return (react_1.default.createElement("section", { className: "flex flex-col items-center" },
        react_1.default.createElement("form", { className: "flex flex-col gap-6", onSubmit: handleSubmit },
            react_1.default.createElement("div", null,
                react_1.default.createElement("h3", { className: "text-left text-xs font-thin capitalize" }, hasBid ? "Selected" : "Select option"),
                react_1.default.createElement("div", { className: "min-w-[40vw] relative flex flex-col gap-4 items-center p-4 border border-black" },
                    react_1.default.createElement(CheckList_1.CheckList, { id: "option", options: options, disabled: hasBid, defaultValue: state?.option, required: true })),
                react_1.default.createElement("div", { className: "flex justify-center gap-2 items-center font-thin" }, users ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("span", null, users || 0),
                    react_1.default.createElement("span", null, "bids"))) : null)),
            isLogged ? (react_1.default.createElement("div", { className: "relative flex flex-col items-center" },
                hasBid ? (react_1.default.createElement("strong", { className: "text-center text-4xl" }, state?.value)) : (react_1.default.createElement(input_1.Input, { id: "value", type: "number", required: true, min: "1", defaultValue: hasBid ? state.value : 1, className: "w-fit text-center text-4xl border-0" })),
                react_1.default.createElement(label_1.Label, { className: "capitalize text-center" }, hasBid ? "your bid" : "input a bid"),
                errorText ? (react_1.default.createElement("p", { className: "absolute -bottom-4 text-center text-xs text-rose-600" }, errorText)) : null)) : null,
            react_1.default.createElement("div", { className: "flex gap-6 justify-center items-center" }, isLogged ? (hasBid ? (react_1.default.createElement(button_1.Button, { type: "reset", onClick: handleCancelBid }, "Remove Bid")) : (react_1.default.createElement(button_1.Button, { primary: true }, "Set Bid"))) : (react_1.default.createElement(auth_1.SignIn, null,
                react_1.default.createElement(button_1.Button, { primary: true }, "Sing In")))))));
}
function BetActiveView({ data }) {
    const { user, options = [], state } = data;
    const isLogged = (0, react_1.useMemo)(() => {
        return user?.email != null;
    }, [user]);
    const hasBid = (0, react_1.useMemo)(() => {
        return state?.option && state?.value;
    }, [state]);
    return options?.length ? (react_1.default.createElement("section", { className: "flex flex-col items-center" },
        react_1.default.createElement("div", { className: "flex flex-col gap-6" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "min-w-[40vw] relative flex flex-col gap-4 items-center p-4 border border-black" },
                    react_1.default.createElement(poll_1.Poll, { options: options, selected: state?.option }))),
            isLogged ? (react_1.default.createElement("div", { className: "flex flex-col items-center" },
                react_1.default.createElement("strong", { className: "text-center text-4xl" }, state?.value || "no bid"),
                react_1.default.createElement(label_1.Label, { className: "capitalize text-center" }, hasBid ? "your bid" : null))) : null,
            react_1.default.createElement("div", null,
                react_1.default.createElement("p", { className: "text-center text-xl font-thin" }, "waiting for results"))))) : null;
}
function BetCloseView({ data }) {
    const { options = [], user, state, result } = data;
    const userId = (0, react_1.useMemo)(() => {
        return user?._id || user?.id;
    }, [user]);
    const reward = (0, react_1.useMemo)(() => {
        if (!userId || !result?.winners) {
            return null;
        }
        return result.winners[userId]?.value;
    }, [userId, result]);
    const hasReward = (0, react_1.useMemo)(() => {
        return !!reward;
    }, [reward]);
    return options?.length ? (react_1.default.createElement("section", { className: "flex flex-col items-center" },
        react_1.default.createElement("div", { className: "flex flex-col gap-6" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "min-w-[40vw] relative flex flex-col gap-4 items-center p-4 border border-black" },
                    react_1.default.createElement(poll_1.Poll, { options: options, selected: state?.option, answer: result?.answer }))),
            hasReward ? (react_1.default.createElement("div", null,
                react_1.default.createElement("p", { className: "text-center text-2xl font-thin" },
                    "your reward: ",
                    react_1.default.createElement("strong", { className: "font-bold" }, reward)))) : (react_1.default.createElement("p", { className: "text-center text-xl font-thin" }, "session closed"))))) : null;
}
