// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rc-drawer {
  /* transition: width 0.5s ease; */
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
}

  .rc-drawer .rc-drawer-mask {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / 0.6);
}

  .rc-drawer .rc-drawer-content-wrapper {
    min-width: var(--min-width);

    transition: width 0.5s ease;

    position: absolute;

    right: 0px;

    z-index: 10;

    height: 100%;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .rc-drawer .rc-drawer-content-wrapper .rc-drawer-content {
  height: 100%;
}
.rc-drawer-right {
  justify-content: flex-end;
}
.rc-drawer:not(.rc-drawer-open) {
  width: 0;
}
.rc-drawer:not(.rc-drawer-open) .rc-drawer-content-wrapper {
    width: 0 !important;
  }
`, "",{"version":3,"sources":["webpack://./client/src/components/atoms/drawer/style.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,eAAwC;EAAxC,QAAwC;EAAxC,UAAwC;EAAxC,YAAwC;EAAxC,WAAwC;AAgB1C;;EAbI;EAAA,kBAA+C;EAA/C,QAA+C;EAA/C,YAA+C;EAA/C,WAA+C;EAA/C;AAA+C;;EAGjD;IACE,2BAA2B;;IAE3B,2BAA2B;;IAC3B,kBAA4C;;IAA5C,UAA4C;;IAA5C,WAA4C;;IAA5C,YAA4C;;IAA5C,kBAA4C;;IAA5C,yDAA4C;EAK9C;;EAFI;EAAA;AAAa;AAKjB;EAAA;AAAkB;AAEpB;EACE,QAAQ;AAKV;AAHE;IACE,mBAAmB;EACrB","sourcesContent":[".rc-drawer {\n  /* transition: width 0.5s ease; */\n  @apply fixed top-0 right-0 w-full h-full;\n\n  .rc-drawer-mask {\n    @apply absolute top-0 w-full h-full bg-black/60;\n  }\n\n  .rc-drawer-content-wrapper {\n    min-width: var(--min-width);\n\n    transition: width 0.5s ease;\n    @apply absolute right-0 h-full z-10 bg-white;\n\n    .rc-drawer-content {\n      @apply h-full;\n    }\n  }\n}\n.rc-drawer-right {\n  @apply justify-end;\n}\n.rc-drawer:not(.rc-drawer-open) {\n  width: 0;\n\n  .rc-drawer-content-wrapper {\n    width: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
