"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppStore = exports.useAppContext = exports.AppContext = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const user_api_1 = require("src/api/user.api");
const initialState = {
    ready: false,
    user: null,
    authUrl: null,
    logged: false,
    sessionFormVisible: false,
    sessionAdminVisible: false,
    targetSession: null,
};
exports.AppContext = (0, react_1.createContext)({
    state: initialState,
    dispatch: (state) => { },
});
const useAppContext = () => {
    const { state, dispatch } = (0, react_1.useContext)(exports.AppContext);
    return { state, dispatch };
};
exports.useAppContext = useAppContext;
const AppStore = ({ children }) => {
    const [state, setState] = (0, react_1.useState)(initialState);
    (0, react_1.useEffect)(() => {
        (0, user_api_1.signIn)()
            .then(({ user, authUrl }) => {
            setState((current) => ({
                ...current,
                user,
                authUrl,
                logged: user != null,
            }));
        })
            .catch((error) => { })
            .finally(() => {
            setState((current) => ({ ...current, ready: true }));
        });
    }, []);
    return (react_1.default.createElement(exports.AppContext.Provider, { value: { state, dispatch: setState } }, children));
};
exports.AppStore = AppStore;
