"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSessionStageLabel = void 0;
const session_model_1 = require("src/models/session.model");
function getSessionStageLabel(stage) {
    switch (stage) {
        case session_model_1.SessionStageType.Draft:
        case session_model_1.SessionStageType.Lobby: {
            return 'lobby';
        }
        case session_model_1.SessionStageType.Active: {
            return 'in progress';
        }
        case session_model_1.SessionStageType.Close: {
            return 'closed';
        }
    }
}
exports.getSessionStageLabel = getSessionStageLabel;
